@import "./antd";

// Sidebar
.sidebar {
  .logo {
    a.logo-normal {
      display: flex;
      align-items: center;
      padding-left: 15px;
      img {
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
}

// Table
table {
  &.table {
    tr {
      td {
        &.action {
          i {
            font-size: 1rem;
            cursor: pointer;
            margin-left: 10px;
          }
        }
        &.avatar {
          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            max-width: 40px;
          }
        }
        &.flag {
          font-size: 1.5rem;
        }
        &.link {
          a {
            color: #212529;
            &:hover {
              color: #51cbce;
            }
          }
        }
        &.hex-color {
          div {
            width: 30px;
            height: 30px;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

// Content
.main-panel {
  min-height: 100vh;
}

p {
  &.all-items-txt {
    text-align: center;
  }
}

button {
  &.btn {
    &.action-btn {
      width: max-content;
    }
    &.modal-btn {
      margin: 0;
    }
  }
}

.custom-modal-content {
  margin-top: 1rem;
}

.multiple-fields {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 15px;
  padding-top: 0;
  &__item {
    margin-left: 15px;
    .ant-select {
      min-width: 150px;
    }
  }
}
