.image-content {
  position: relative;
  img {
    position: absolute;
  }
  .react-loading-skeleton {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
  }
}
