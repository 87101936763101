.loading-content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 50px;
  .ant-spin {
    .anticon {
      font-size: 30px;
      color: #51cbce;
    }
  }
}
