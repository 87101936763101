.user-detail-content {
  &__avatar {
    display: flex;
    align-items: center;
    &__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 2rem;
      button {
        margin: 0;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
  &__form {
    max-width: 800px;
    margin-top: 3rem;
  }
}
