.type-detail-content {
  &__image {
    display: flex;
    align-items: center;
  }
  &__media {
    margin: 2rem 0;
    h3 {
      margin: 0;
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    &__content {
      display: flex;
      align-items: center;
      video {
        width: 150px;
        height: 150px;
        border-radius: 5px;
      }
      &__actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 2rem;
        button {
          margin: 0;
          margin-top: 5px;
          margin-bottom: 5px;
          width: 120px;
        }
      }
    }
  }
  &__form {
    max-width: 800px;
    margin-top: 3rem;
    .pick-color {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .pick-color__square {
        width: 30px;
        height: 30px;
        border-radius: 10px;
      }
      p {
        color: #51cbce;
        cursor: pointer;
        text-decoration: underline;
        margin: 0;
        margin-left: 10px;
      }
      .chrome-picker {
        position: absolute;
        z-index: 999;
        top: 38px;
      }
    }
  }
}
