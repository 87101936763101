.ant-input {
  height: 40px;
}

.ant-input-affix-wrapper {
  &.default {
    height: 38px;
    padding-top: 0;
    padding-bottom: 0;
    input {
      height: 35px;
    }
  }
}

.ant-input-password {
  padding-top: 0;
  padding-bottom: 0;
}

.ant-btn {
  height: 40px;
  width: 100%;
  margin-top: 10px;
}

.ant-select {
  min-height: 38px;
  .ant-select-selector {
    min-height: 38px;
    height: auto;
  }
  &.default {
    .ant-select-selector {
      min-height: 38px;
      height: auto;
      .ant-select-selection-item {
        line-height: 38px;
      }
      .ant-select-selection-search {
        input {
          height: 38px;
        }
      }
    }
  }
}

.ant-form {
  &.default {
    .ant-form-item {
      .ant-form-item-label {
        label {
          flex-direction: row-reverse;
          &::before {
            margin-left: 3px;
          }
          &::after {
            display: none;
          }
        }
      }
      .ant-form-item-explain {
        margin-bottom: 5px;
        .ant-form-item-explain-error {
          font-size: 0.8rem;
          margin-top: 5px;
        }
      }
    }
  }
}

.ant-modal-root {
  .ant-modal-mask {
    z-index: 2000;
  }
  .ant-modal-wrap {
    z-index: 2100;
    .ant-modal-content {
      .ant-modal-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}

.ant-switch {
  &.default {
    &.ant-switch-checked {
      background: #51cbce;
      &:hover {
        background: #51cbce;
      }
    }
  }
}