.crop-image-content {
  &__input {
    margin: 1rem 0;
  }
  .ReactCrop {
    width: 100%;
  }
  .action-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
