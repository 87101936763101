.login-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &__form {
    width: 40%;
    max-width: 500px;
  }
}
